<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">   
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">상담원 추가</v-col>
            </v-row>        
        </v-card-title>

        <v-card-text class="pb-0 pl-7 pr-7 pt-3">
            <v-row no-gutters class="pb-5">
                <v-col cols="12" class="pb-0 title black--text">                    
                    <div style="overflow-y:auto; max-height:200px; border:1px solid #eeeeee; ">
                        <v-list>
                            <v-list-item-group multiple v-model="checkList">
                                <template  v-for="(userItem, userIdx) in filteredUserList">
                                    <v-divider v-if="userIdx > 0" :key="'divider_' + userIdx"></v-divider>
                                    <v-list-item v-bind:key="userIdx" :value="userItem.user_seqno">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-action>
                                                <v-checkbox
                                                :input-value="active"
                                                color="blue accent-4"
                                                ></v-checkbox>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="userItem.user_name"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>   
                                    </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="checkList.length > 0">
                <v-col cols="12">
                    <v-chip v-for="(userItem, userIdx) in checkedUserChips" :key="'checked_' + userIdx" close class="mr-2 mb-2" color="blue accent-2" text-color="white" @click="uncheckUser(userItem)" @click:close="uncheckUser(userItem)">{{userItem.user_name}}</v-chip>
                </v-col>
            </v-row>
        </v-card-text>        
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-8 pb-3 pt-3 text-right block-bt">
                    <v-btn class="ma-2" width="130px" color="primary" text  v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">추가</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
    
</template>

<script>
import { mapGetters } from 'vuex'
import svcStaffApi from '@/api/svc_staff'

export default {
    data: () => ({
        allUserList: [],
        checkList: []
    }),
    computed: {
        ...mapGetters({
            curMenu: 'svcVoiceScn/getSelectedMenu',
        }),        
        checkedUserChips () { 
            let ret = []

            let va = this
            this.checkList.forEach(item => { 
                va.allUserList.forEach(userItem => { 
                    if(item == userItem.user_seqno) { 
                        ret.push(userItem)
                    }
                })
            })

            return ret
        },
        filteredUserList () {
            let ret = []
            let usedUserList = this.curMenu.ring_user_list
            this.allUserList.forEach(item => { 
                let usedFlag = false
                if(usedUserList) { 
                    usedUserList.forEach(usedItem => { 
                        if(usedItem.user_seqno == item.user_seqno) { 
                            usedFlag = true
                        }
                    })
                }
                if(usedFlag == false) { 
                    ret.push(item)
                }
            })

            return ret
        }
    },    
    mounted() {
        let va = this
        svcStaffApi.getList({}, (userList) => { 
            if(userList && userList.length > 0) { 
                userList.forEach(item => { 
                    va.allUserList.push(item)
                })
            }        
        }, null, this)
    },
    methods: {
        closePopup() { 
            this.$emit('cancel')
        },
        submitPopup() { 
            let parentMenuSeqno = this.curMenu.menu_seqno
            let addList = []
            let va = this
            this.checkList.forEach(seqno => { 
                va.allUserList.forEach(userItem => { 
                    if(userItem.user_seqno == seqno) { 
                        addList.push({
                            menu_seqno: 0,
                            parent_menu_seqno: parentMenuSeqno,
                            user_seqno: userItem.user_seqno,
                            user_name: userItem.user_name,
                            user_mdn: userItem.user_mdn,
                            mng_memo: userItem.mng_memo,
                        })
                    }
                })
                
            })
            this.$store.dispatch('openConfirm', {
                message: '상담원을 추가 하시겠습니까?', 
                va: this,
                okCb: function(va) { 
                    va.$store.commit('svcVoiceScn/addRingUserList', addList)
                    va.$emit('submit')
                }
            })
        }, 
        uncheckUser (item) {
            let idx = this.checkList.indexOf(item.user_seqno)
            if(idx >= 0) { 
                this.checkList.splice(idx, 1)
            } else {
                console.log('uncheckUser : idx=' + idx, item)
            }
        }        
    }
}
</script>
